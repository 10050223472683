import "reflect-metadata";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { ConfigProgrammatic, Button, Tooltip, Field, Input, Select, Notification, Modal } from "buefy";
import { BRadioButton } from "buefy/dist/esm/radio";
import PortalVue from "portal-vue";
import EasySpinner from "vue-easy-spinner";

import { formatCurrency, formatDate, humanizeDate } from "~/utils";
import { Page } from "./page";
import { IntranetMovieSchedule } from "~/intranet-movie-schedule";
import { SvgIcon } from "~/common/svg-icon";
import SimpleTooltip from "~/common/simple-tooltip";
import CommonNavigation from "./navigation/common-navigation";
import NotificationModal from "~/common/notification-modal";
import NewsletterSignup from "~/newsletter/newsletter-signup";
import NewsletterButton from "~/newsletter/newsletter-button";

ConfigProgrammatic.setOptions({
	defaultIconComponent: SvgIcon as any,
	defaultIconPack: "icon",
});

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.component("svg-icon", SvgIcon);

			app.use(Select);
			app.use(Button);
			app.use(Tooltip);
			app.use(Field);
			app.use(Input);
			app.use(Notification);
			app.use(Modal);
			app.component("b-radio-button", BRadioButton);

			app.use(PortalVue);
			app.use(EasySpinner);

			app.config.globalProperties.$formatters = {
				formatDate,
				humanizeDate,
				formatCurrency,
			};

			app.config.globalProperties.$colors = {
				red: "#af272f",
				darkred: "#4d0d10",
				lightgray: "#eeeeee",
				darkgray: "#333333",
			};
		},
	})
}

customElements.define("simple-tooltip", defineAndConfigureCustomElement(SimpleTooltip));
customElements.define("common-navigation", defineAndConfigureCustomElement(CommonNavigation));
customElements.define("notification-modal", defineAndConfigureCustomElement(NotificationModal));
customElements.define("newsletter-signup", defineAndConfigureCustomElement(NewsletterSignup));
customElements.define("newsletter-button", defineAndConfigureCustomElement(NewsletterButton));

if (document.querySelector("movie-overview") != null) {
	import("./filter/movie-overview").then(m => {
		customElements.define("movie-overview", defineAndConfigureCustomElement(m.default));
	});
}

if (document.querySelector("movie-tickets") != null) {
	import("./tickets/movie-tickets").then(m => {
		customElements.define("movie-tickets", defineAndConfigureCustomElement(m.default));
	});
}

if (document.querySelector("special-ticket") != null) {
	import("./tickets/special-ticket").then(m => {
		customElements.define("special-ticket", defineAndConfigureCustomElement(m.default));
	});
}

if (document.querySelector("shop-form") != null) {
	import("./shop/shop-form").then(m => {
		customElements.define("shop-form", defineAndConfigureCustomElement(m.default));
	});
}

new Page();

if (document.body.classList.contains("intranet-movie-schedule")) {
	new IntranetMovieSchedule();
}

import SlabText from "./slab-text";
let slogan = document.querySelector(".slogan");
if (slogan != null) {
	window.addEventListener("load", () => new SlabText(slogan!));
}

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

import "../Styles/index.scss";
